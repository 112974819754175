<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { useConstantsStore } from '@/store/constants-store';
import { RouteNames } from '@/router/routes/route-names.enum';
import { IscWrapperPage } from '@ui/components';
import { onMounted, onUnmounted, ref } from 'vue';
import { GetCookieConsent } from '@/common/utils/cookie-yes';

const route = useRoute();
const router = useRouter();
const getLabelValue = useConstantsStore().getLabelValue;
const timeOut = parseInt(route.query.timeout as string ?? 0, 10);

// When redirected from Angular, we need to remove the enclosing parentheses of labels
const trimParentheses = (label: string) => label.replace(/^\(|\)$/g, '');

const landingTitle = getLabelValue(trimParentheses(route.query.landingTitle as string));
const landingMessage = ref(getLabelValue(trimParentheses(route.query.landingMessage as string)));
let interval: null | ReturnType<typeof setTimeout> = null;

if(timeOut >= 0) {
  setTimeout(() => {
    if (route.query.isExternalLink !== 'true') {
      const query = route.query.redirectParams ? JSON.parse(route.query.redirectParams as string) : {};
      if(route.query.redirectToMobileAppHome === 'true') {
        query.autologin = true; // hack for old mobile app, is this still needed?
      }
      router.push({
        name: route.query.redirectToMobileAppHome === 'true' ? RouteNames.Home : route.query.redirectState as string,
        query,
      });
    } else {
      window.location.href = route.query.redirectState as string;
    }
  }, timeOut || 1);
}

onMounted(() => {
  if(route.query.isCookiePage === 'true') {
    watchCookieChanges();
  }
});

onUnmounted(() => {
  removeInterval();
});

const watchCookieChanges = () => {
  interval = setInterval(() => {
    const cookieConsent = GetCookieConsent();
    if(!cookieConsent) {
      return;
    }

    // The user will need to refresh to bring back the cookiebanner so we can clear the interval here to
    if(!cookieConsent.action || !cookieConsent.consent) {
      return;
    }
    // If there is an action recorded and he did not give his consent, show another message on the page
    if(cookieConsent.action === 'yes' && cookieConsent.consent === 'no') {
      landingMessage.value = getLabelValue('LabelWrongCookieSettingSelectedMessage');
      removeInterval();
    } else if(cookieConsent.action === 'yes' && cookieConsent.consent === 'yes') {
      // If there is an action recorded and he did give his consent, redirect him to the activity link
      redirectToIntakeSurvey();
      removeInterval();
    }
  }, 1000);
};

const removeInterval = () => {
  if(interval) {
    clearInterval(interval);
  }
};

const redirectToIntakeSurvey = () => {
  const params = route.query.redirectParams ? JSON.parse(route.query.redirectParams as string) : {};
  router.push({
    name: RouteNames.ActivityIntake,
    params,
    query: {
      ...route.query,
    },
  });
};

</script>

<template>
  <isc-wrapper-page
    :top-margin="false"
    :x-padding="false"
    :y-padding="false"
    class="landing-page q-pa-xl"
  >
    <div
      class="landing-page__title text-insites-blue text-center q-mt-xl"
      :class="$q.screen.gt.xs ? 'text-h1 q-mb-xl q-mx-xl q-px-xl' : 'text-h4 q-mb-lg'"
    >
      {{ landingTitle }}
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="landing-page__message text-subtitle1 text-weight-medium text-center q-pb-xl"
      v-html="landingMessage"
    />
    <!-- eslint-enable vue/no-v-html -->
  </isc-wrapper-page>
</template>
