import { __awaiter } from "tslib";
const changeEmailTemplate = "﻿<md-dialog class=\"insites-dialog change-email-dialog\" isc-translate=\"[aria-label](LabelProfileEditAccountChangeEmailDialogTitle)\">\n  <md-toolbar>\n    <div class=\"md-toolbar-tools\">\n      <h2 isc-translate=\"(LabelProfileEditAccountChangeEmailDialogTitle)\"></h2>\n    </div>\n  </md-toolbar>\n  <md-dialog-content>\n    <div layout=\"row\" class=\"message\">\n      <span isc-translate=\"(LabelProfileEditAccountChangeEmailDialogMessage)\" isc-translate-changeemailvalidityhours=\"{{vm.validationConstants.changeEmailHoursValid}}\"></span>\n    </div>\n    <div layout=\"row\" layout-align=\"end\">\n      <md-button class=\"md-primary md-raised isc-button\" ng-click=\"vm.redirect()\" isc-translate=\"(LabelProfileEditAccountChangeEmailDialogButton)\"></md-button>\n    </div>\n  </md-dialog-content>\n</md-dialog>";
export class ProfileEditController {
    constructor(participantService, $state, _, participantFactory, $mdDialog, iscTranslate, $q, $scope, userService, squareFactory, serverConstants, featureService, __env) {
        this.participantService = participantService;
        this.$state = $state;
        this._ = _;
        this.participantFactory = participantFactory;
        this.$mdDialog = $mdDialog;
        this.iscTranslate = iscTranslate;
        this.$q = $q;
        this.$scope = $scope;
        this.userService = userService;
        this.squareFactory = squareFactory;
        this.serverConstants = serverConstants;
        this.featureService = featureService;
        this.__env = __env;
        this.editPassword = false;
        this.userNameRegex = new RegExp(/^((?![&|@])(?![ ]{2,})(?![.]{3,}).)*$/);
        this.currentPassword = '';
        this.password = '';
        this.confirmPassword = '';
        this.validationConstants = this.serverConstants.validationConstants;
        this.isAzureAdB2CLoginEnabled = undefined;
        this.shouldShowPasswordSection = false;
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.userService.hasParticipantRole()) {
                this.$state.go('container.main.profile');
            }
            this.participantFactory.loadingPromise.then(() => {
                this.fetchParticipantDetails();
            });
            this.$scope.$watch(() => this.userService.userGuid(), (newValue) => {
                if (newValue !== this.userService.userGuid()) {
                    this.participantService.squareParticipantRequestedEmailChange(newValue).then((response) => {
                        if (response && response.data) {
                            this.awaitingEmailConfirmation = response.data.HasValidEmailChangeRequest;
                        }
                    });
                }
            });
            this.isAzureAdB2CLoginEnabled =
                yield this.featureService.isMasterFeatureEnabledForSquare(this.serverConstants.featureConstants.azureAdB2CLogin);
        });
    }
    fetchParticipantDetails() {
        this.participantService.SquareParticipantDetails().then((response) => {
            this.participant = response.Detail;
            this.participant.PublicSegmentationsWithSegments = response.PublicSegmentationsWithSegments;
            this.participant.ActivitiesSubmissions = response.ActivitiesSubmissions;
            this.participant.ActivitiesAcceptedSubmissions = response.ActivitiesAcceptedSubmissions;
            this.participant.ActivitiesCompleted = response.ActivitiesCompleted;
            this.shouldShowPasswordSection = response.AccountTypeAndSocialLoginType ?
                response.AccountTypeAndSocialLoginType.AccountType !== this.serverConstants.accountTypeConstants.socialLogin
                : true;
        });
    }
    onSave() {
        this.dataLoading = true;
        const participantProfile = {
            SquareParticipantId: parseInt(this.userService.userId(), 10),
            SquareParticipantGuid: this.userService.userGuid(),
            Username: this.participant.Username,
            Password: this.password,
            Email: this.participant.Email,
            About: this.participant.About,
            PhoneNumber: this.participant.PhoneNumber,
            ConfirmPassword: this.confirmPassword,
            CurrentPassword: this.currentPassword,
            AddressLine1: this.participant.AddressLine1,
            AddressLine2: this.participant.AddressLine2,
            City: this.participant.City,
            CountryNativeName: this.participant.CountryNativeName,
            ZIPPostalCode: this.participant.ZIPPostalCode,
            StateRegion: this.participant.StateRegion,
            FirstName: this.participant.FirstName,
            LastName: this.participant.LastName,
        };
        this.participantService.updateSquareParticipantProfile(participantProfile).then(() => {
            this.participantFactory.Username = this.participant.Username;
            this.participantFactory.Email = this.participant.Email;
            this.participantFactory.PhoneNumber = this.participant.PhoneNumber;
            this.participantService.squareParticipantRequestedEmailChange(participantProfile.SquareParticipantGuid).then((response) => {
                if (!response || !response.data) {
                    this.$state.go('container.main.profile');
                    this.participantService.profileEditorClosed.next(true);
                    return;
                }
                this.awaitingEmailConfirmation = response.data.HasValidEmailChangeRequest;
                if (!this.awaitingEmailConfirmation) {
                    this.$state.go('container.main.profile');
                    this.participantService.profileEditorClosed.next(true);
                }
                else {
                    this.awaitingEmailConfirmation = this.editForm.Email.$dirty;
                    this.$mdDialog.show({
                        controller: 'ChangeEmailDialogController',
                        controllerAs: 'vm',
                        template: changeEmailTemplate,
                        parent: angular.element(document.body),
                        clickOutsideToClose: false,
                    });
                }
            });
        }, (error) => {
            if (error.status === 400) {
                const data = error.data;
                const grouped = this._.groupBy(data.ValidationErrors, 'PropertyName');
                this._.forEach(grouped, (item, key) => {
                    let message = '';
                    this._.forEach(item, (errorMessage) => {
                        message += `${errorMessage.ErrorMessage} `;
                    });
                    this.editForm[key].$setValidity('serverErrors', false);
                    this.editForm[key].errorMessage = message;
                });
            }
        }).finally(() => {
            this.dataLoading = false;
        });
    }
    onCancel() {
        this.currentPassword = '';
        this.password = '';
        this.confirmPassword = '';
        this.$state.go('container.main.profile');
        this.participantService.profileEditorClosed.next(true);
    }
    onChangePasswordClicked() {
        if (this.isAzureAdB2CLoginEnabled === undefined) {
            return;
        }
        if (this.isAzureAdB2CLoginEnabled) {
            let authUrl = `${this.__env.authenticationBase}resetPassword/?return_uri=${encodeURIComponent(window.location.href)}`;
            const locale = this.squareFactory.Language;
            if (locale) {
                authUrl += `&locale=${locale}`;
            }
            window.location.href = authUrl;
            return;
        }
        if (this.editPassword) {
            this.currentPassword = '';
            this.password = '';
            this.confirmPassword = '';
        }
        this.editPassword = !this.editPassword;
    }
    addUnsubscribeDialog($event) {
        const labelTitle = this.iscTranslate.replaceLabels('(LabelUnsubscribeDialogTitle)', { squarename: this.squareFactory.SquareName.toUpperCase() });
        const labelContent = this.iscTranslate.replaceLabels('(LabelUnsubscribeDialogContent)', { squarename: this.squareFactory.SquareName });
        const labelCancel = this.iscTranslate.replaceLabels('(LabelUnsubscribeDialogCancel)');
        const labelOk = this.iscTranslate.replaceLabels('(LabelUnsubscribeDialogOk)');
        this.$q
            .all([labelTitle, labelContent, labelCancel, labelOk])
            .then(([title, content, cancel, ok]) => {
            const dialog = this.$mdDialog.confirm()
                .targetEvent($event)
                .title(title)
                .ariaLabel(title)
                .htmlContent(content)
                .cancel(cancel)
                .ok(ok);
            return this.$mdDialog.show(dialog);
        }).then(() => {
            this.$state.go('container.unsubscribe', { sqPartId: this.participantFactory.Guid });
        });
    }
    setValidity(key) {
        this.editForm[key].$setValidity('serverErrors', true);
    }
    goToPublicProfile() {
        const publicProfileUrl = this.$state.href('container.main.publicProfile', {
            username: this.participantFactory.Username,
        }, {
            absolute: true,
        });
        window.open(publicProfileUrl, '_blank');
    }
    get showTotalActivitiesCount() {
        return this.participant && this.participant.ActivitiesCompleted;
    }
    get showTotalContributionsCount() {
        return this.participant && this.participant.ActivitiesSubmissions;
    }
    get showAcceptedContributionsCount() {
        return this.participant && this.participant.ActivitiesAcceptedSubmissions;
    }
}
ProfileEditController.$inject = ['participantService', '$state', '_', 'participantFactory',
    '$mdDialog', 'iscTranslate', '$q', '$scope', 'userService', 'squareFactory', 'serverConstants', 'featureService', '__env'];
